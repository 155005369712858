<template>
  <UserLayout :title="awardTitle" :layout="layouts.NO_SIDEBAR">
    <div class="awards_wrapper">
      <LxpBreadcrumb :items="breadcrumbsArr" />
      <h1 class="aw-title">{{ awardTitle }}</h1>
      <LxpLoader v-if="isInitLoading" />
      <b-container fluid class="aw-container" v-else>
        <div class="aw-header">
          <div class="aw-heading_txt">
            Defining award metadata
          </div>
          <div
            class="aw-content_txt"
            v-if="awardType === 'course-completion-award'"
          >
            Next, let's define the award metadata. This includes details like
            the award validity and description, providing clarity and
            significance to learners. Let's customize this metadata to
            accurately represent the award's importance in your course
          </div>
          <div class="aw-content_txt" v-else>
            Let's define the award metadata. This includes details like the
            award validity and description, providing clarity and significance
            to learners. Let's customize this metadata to accurately represent
            the award's importance in your course
          </div>
        </div>
        <div class="aw-editor__form aw-editor__validity">
          <div class="aw-editor__validity--select">
            <LxpDropdown
              :dropdownArr="awardValidity"
              label="Select award validity*"
              @click="onSelectChange($event)"
              v-model.trim="$v.form.validity_in_years.$model"
              :isFloating="true"
              :showError="$v.form.validity_in_years.$error"
              :errorMessage="
                $v.form.validity_in_years.$error
                  ? 'Award Validity is required'
                  : ''
              "
            />
          </div>
          <div v-if="isDateOpen" class="aw-editor__validity--date">
            <expiration-date
              id="course-expiration-date"
              label="Expiration date*"
              :hasError="$v.form.expiration_date.$error"
              :supportText="
                $v.form.expiration_date.$error
                  ? 'Expiration Date is required'
                  : ''
              "
              :min="minDate"
              :value="form.expiration_date"
              @input="e => (form.expiration_date = e)"
            />
          </div>
        </div>
        <div
          class="aw-editor__form"
          v-if="awardType === 'course-participation-confirmation'"
        >
          <LxpTextEditor
            class="lxp-editor"
            placeholder="Insert names of technical units/issuer separated by comma(,)"
            :limit="300"
            v-model.trim="$v.form.issuers.$model"
            @input="$v.form.issuers.$touch()"
            :hasError="$v.form.issuers.$error"
            label="Technical unit/issuer name(s)*"
            id="issuer_name"
            name="issuer_name"
          />
          <span class="error" v-if="$v.form.issuers.$error"
            >Issuer name is required</span
          >
        </div>
        <div
          class="aw-editor__form upload__section"
          v-if="awardType === 'course-participation-confirmation'"
        >
          <div class="aw-upload__img">
            <h6>Upload technical unit/issuer logo(s) (optional)</h6>
            <p>Format: .jpeg or .png; Max size: 500kb; Max 4 logos</p>
          </div>
          <input
            type="file"
            accept=".png,.jpeg"
            multiple
            aria-label="upload"
            id="lxp-file-input-issuer-logo"
            @change="uploadOnChange"
          />
          <LxpButton
            type="primary"
            size="sm"
            variant="outline-primary"
            :disabled="form.issuer_logo.length >= 4"
          >
            <label
              class="aw-upload__img--content"
              for="lxp-file-input-issuer-logo"
            >
              <img
                src="@/assets/images/awards/form/upload-logo.svg"
                alt="upload logo"
              />
              Upload File
            </label>
          </LxpButton>
        </div>

        <div v-if="form.issuer_logo.length > 0" class="aw-images">
          <div
            v-for="(image, index) in form.issuer_logo"
            :key="index"
            class="aw-image__container"
          >
            <img
              :src="image.url || image.logo"
              alt="Uploaded Image"
              style="max-width: 200px; margin: 5px;"
              class="aw-image__container--img"
            />
            <div class="aw-image__container__remove" @click="removeImg(index)">
              <img
                src="https://files.lxp.academy.who.int/didactica/assets/images/drag-drop/remove.svg"
                alt="remove"
              />
            </div>
          </div>
        </div>

        <div class="aw-editor__form">
          <LxpTextEditor
            class="lxp-editor"
            label="Admission criteria*"
            placeholder="Insert admission criteria description"
            :limit="1000"
            :hasError="$v.form.admission_criteria.$error"
            v-model.trim="$v.form.admission_criteria.$model"
            :value="form.admission_criteria"
            @input="$v.form.admission_criteria.$touch()"
          />
          <span class="error" v-if="$v.form.admission_criteria.$error"
            >Admission criteria is required</span
          >
        </div>

        <div class="aw-editor__form">
          <LxpTextEditor
            class="lxp-editor"
            label="Course description*"
            placeholder="Insert course description"
            :limit="250"
            :hasError="
              $v.form.course_description.$error &&
                !$v.form.course_description.$pending
            "
            v-model.trim="$v.form.course_description.$model"
            @input="$v.form.course_description.$touch()"
          />
          <span
            class="error"
            v-if="
              $v.form.course_description.$error &&
                !$v.form.course_description.$pending
            "
            >Course description is required</span
          >
        </div>
        <div
          class="aw-editor__form"
          v-if="awardType === 'course-completion-award'"
        >
          <LxpTextEditor
            class="lxp-editor"
            label="Course activities*"
            placeholder="Insert course activities description"
            :limit="1000"
            v-model="form.course_activity"
            :hasError="$v.form.course_activity.$error"
          />
          <span class="error" v-if="$v.form.course_activity.$error"
            >Course Activities is required</span
          >
        </div>
        <div
          class="aw-editor__form"
          v-if="awardType === 'course-completion-award'"
        >
          <LxpTextEditor
            class="lxp-editor"
            label="Course outcomes*"
            placeholder="Insert course outcomes description"
            :limit="1000"
            v-model="form.course_outcome"
            :hasError="$v.form.course_outcome.$error"
          />
          <span class="error" v-if="$v.form.course_outcome.$error"
            >Course Outcomes is required</span
          >
        </div>
        <div class="aw-line"></div>
        <div class="aw-optional__fields">
          <div class="aw-heading_txt">
            Optional fields
          </div>
          <div class="w-100" v-for="(f, i) in optionalFields" :key="i">
            <div class="aw-editor__form" v-if="fieldsArr.includes(f.key)">
              <div
                class="aw-optional__field"
                v-if="!f.isOpen"
                @click="f.isOpen = true"
              >
                <img
                  src="@/assets/images/awards/form/add.svg"
                  alt="medal"
                  width="24"
                  height="24"
                />
                <p>{{ f.note }}</p>
              </div>
              <LxpTextEditor
                class="lxp-editor"
                :label="f.label"
                :placeholder="f.placeholder"
                :limit="1000"
                v-model="form[f.key]"
                v-else
              />
            </div>
          </div>
        </div>
        <div class="aw-editor__btn">
          <LxpButton variant="primary" @click="goNext">
            <LxpLoader v-if="isLoading" />
            <span v-else>Next</span>
          </LxpButton>
        </div>
      </b-container>
    </div>
  </UserLayout>
</template>
<script>
import {
  LxpBreadcrumb,
  LxpTextEditor,
  LxpDropdown,
  LxpButton,
  LxpLoader
} from "didactica";
import moment from "moment";
// validation
import { requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// components
import UserLayout, { LAYOUTS } from "@/components/Layout/UserLayout.vue";
import ExpirationDate from "./AwardForm/ExpirationDate.vue";
import { mapGetters } from "vuex";

const isNotEmpty = value =>
  value !== "" && value !== "<p><br></p>" && value !== "\n";
export default {
  mixins: [validationMixin],
  components: {
    UserLayout,
    LxpBreadcrumb,
    LxpTextEditor,
    LxpDropdown,
    LxpLoader,
    LxpButton,
    ExpirationDate
  },
  data() {
    return {
      awardValidity: [
        {
          name: "Does not expire",
          id: null
        },
        {
          name: "Custom(select a specific date)",
          id: "custom"
        }
      ],
      layouts: LAYOUTS,
      minDate: moment().format("YYYY-MM-DD"),
      form: {
        validity_in_years: "",
        expiration_date: null,
        issuers: "",
        admission_criteria: "",
        course_description: "",
        course_activity: "",
        course_outcome: "",
        course_assessments: "",
        other_information: "",
        issuer_logo: []
      },
      optionalFields: [
        {
          label: "Course activities (optional)",
          placeholder: "Insert course activities description",
          key: "course_activity",
          isOpen: false,
          note: "Add course activities description (optional)"
        },
        {
          label: "Course outcomes (optional)",
          placeholder: "Insert course outcomes description",
          key: "course_outcome",
          isOpen: false,
          note: "Add course outcomes description (optional)"
        },
        {
          label: "Course assessments (optional)",
          placeholder: "Insert assessments description",
          key: "course_assessments",
          isOpen: false,
          note: "Add course assessments description (optional)"
        },
        {
          label: "Other information (optional)",
          placeholder: "Insert other information",
          key: "other_information",
          isOpen: false,
          note: "Add other information (optional)"
        }
      ],
      isDateOpen: false,
      isLoading: false,
      isInitLoading: true
    };
  },
  validations() {
    if (this.awardType === "course-completion-award") {
      return {
        form: {
          validity_in_years: {
            required: requiredIf(() => {
              return this.form.expiration_date === "";
            })
          },
          expiration_date: {
            required: requiredIf(() => {
              return this.form.validity_in_years === "";
            })
          },
          admission_criteria: {
            required: isNotEmpty
          },
          course_description: {
            required: isNotEmpty
          },
          course_activity: {
            required: isNotEmpty
          },
          course_outcome: {
            required: isNotEmpty
          }
        }
      };
    } else if (this.awardType === "course-participation-confirmation") {
      return {
        form: {
          validity_in_years: {
            required: requiredIf(() => {
              return this.form.expiration_date === null;
            })
          },
          expiration_date: {
            required: requiredIf(() => {
              return this.form.validity_in_years === "";
            })
          },
          issuers: {
            required: isNotEmpty
          },
          admission_criteria: {
            required: isNotEmpty
          },
          course_description: {
            required: isNotEmpty
          }
        }
      };
    }
  },
  computed: {
    ...mapGetters(["getAward"]),
    courseId() {
      return this.$route.params.id;
    },
    awardType() {
      return this.$route.params.type;
    },
    isEdit() {
      return this.$route.path.includes("edit");
    },
    awardTitle() {
      let title = "";
      if (this.awardType === "course-completion-award") {
        title = "Award of Completion";
      } else if (this.awardType === "course-participation-confirmation") {
        title = "Confirmation of Participation";
      }
      return title;
    },
    fieldsArr() {
      let fields = [];
      if (this.awardType === "course-participation-confirmation") {
        fields = this.optionalFields.map(op => op.key);
      } else if (this.awardType === "course-completion-award") {
        fields = this.optionalFields.map(op => {
          if (
            op.key === "course_assessments" ||
            op.key === "other_information"
          ) {
            return op.key;
          }
        });
      }
      return fields;
    },
    breadcrumbsArr() {
      return [
        {
          text: "Settings",
          to: { name: "Awards" } //TODO update to lms url
        },
        {
          text: "Awards",
          to: { name: "Awards", params: { id: this.courseId } }
        }
      ];
    }
  },
  mounted() {
    if (this.isEdit) {
      if (this.getAward?.credential) {
        this.updateForm();
      } else {
        this.$store
          .dispatch("fetchAwards", { courseId: this.courseId })
          .then(() => {
            this.updateForm();
          });
      }
    } else {
      this.isInitLoading = false;
    }
  },
  methods: {
    onSelectChange(data) {
      this.form.validity_in_years = data;
      if (data.id === "custom") {
        this.isDateOpen = true;
      } else {
        this.isDateOpen = false;
      }
    },

    uploadOnChange(e) {
      let files = e.target.files;
      this.form.issuer_logo = [];
      for (let i = 0; i < files.length; i++) {
        if (i < 4) {
          const file = files[i];
          if (file.size <= 500000) {
            this.uploadFileAndGetUrl(file)
              .then(url => {
                this.form.issuer_logo.push({ url: url, file: file });
              })
              .catch(error => {
                console.error("Error uploading file:", error);
              });
          }
        }
      }
    },
    removeImg(index) {
      this.form.issuer_logo.splice(index, 1);
    },
    uploadFileAndGetUrl(file) {
      return new Promise(resolve => {
        setTimeout(() => {
          const url = URL.createObjectURL(file);
          resolve(url);
        }, 1000);
      });
    },

    async goNext() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        let formObj = {
            admission_criteria: this.form.admission_criteria,
            course_description: this.form.course_description,
            course_activity: this.form.course_activity,
            course_outcome: this.form.course_outcome,
            course_assessments: this.form.course_assessments,
            other_information: this.form.other_information
          },
          type;
        this.isEdit ? (type = "edit") : (type = "create");
        if (this.form.validity_in_years.id === "custom") {
          formObj.expiration_date = this.form.expiration_date;
        }
        if (this.awardType === "course-completion-award") {
          this.createCredentials("createAwardOfCompletion", type, formObj);
        } else if (this.awardType === "course-participation-confirmation") {
          formObj.issuers = this.form.issuers;
          let formData = new FormData();
          Object.keys(formObj).forEach(f => {
            formData.append(f, formObj[f]);
          });
          if (this.form.issuer_logo.length > 0) {
            this.form.issuer_logo.forEach(u => {
              if (!u.logo) {
                formData.append("issuer_logo", u.file);
              }
            });
          }
          this.createCredentials(
            "createCompletionOfParticipation",
            type,
            formData
          );
        }
      }
    },
    createCredentials(method, type, formObj) {
      this.$store
        .dispatch(method, {
          courseId: this.courseId,
          type: type,
          form: formObj
        })
        .then(() => {
          this.navigateToAwards();
        })
        .catch(() => {
          this.isLoading = false;
          // TODO handle error
        });
    },
    navigateToAwards() {
      this.isLoading = false;
      this.$router.push({
        name: "Awards",
        params: { id: this.courseId }
      });
    },
    updateForm() {
      this.form.admission_criteria = this.getAward.credential.admission_criteria;
      this.form.course_description = this.getAward.credential.course_description;
      this.form.course_activity = this.getAward.credential.course_activity;
      this.form.course_outcome = this.getAward.credential.course_outcome;
      this.form.course_assessments = this.getAward.credential.course_assessments;
      this.form.other_information = this.getAward.credential.other_information;

      this.form.validity_in_years = this.awardValidity.find(a => {
        let id =
          this.getAward.credential.expiration_date === null ? null : "custom";
        return a.id === id;
      });
      this.form.expiration_date = this.getAward.credential.expiration_date;
      if (this.getAward.credential.expiration_date !== null) {
        this.isDateOpen = true;
      }
      if (this.awardType === "course-participation-confirmation") {
        this.form.issuer_logo = this.getAward.credential.issuer_logos;
        this.form.issuers = this.getAward.credential.issuers;
      }
      this.optionalFields.forEach(o => {
        if (this.getAward.credential[o.key]) {
          o.isOpen = true;
        }
      });
      this.isInitLoading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.awards_wrapper {
  .aw-title {
    color: #00205c;
    /* WHOA Text Styles/headline/h5 */
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 125% */
    letter-spacing: 0.07px;
    padding: 10px 0;
  }
  .aw-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  }
  .aw-images {
    display: flex;
  }
  .aw-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--1, 8px);

    align-self: stretch;
  }
  .aw-line {
    height: 1px;
    background: #c8c8c8;
    width: 100%;
    margin-top: 14px;
  }
  .aw-optional__fields {
    width: 100%;
    .aw-heading_txt {
      margin-bottom: 24px;
    }
    .w-100 {
      &:last-child {
        .aw-editor__form {
          margin-bottom: 0;
        }
      }
      .aw-editor__form {
        margin-bottom: 24px;
      }
    }
  }
  .aw-heading_txt {
    color: $brand-primary;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.15px;
  }
  .aw-content_txt {
    color: var(
      --SEMANTIC-On-Surface-2,
      var(--Semantic-SURFACE-OnSurface2, #434343)
    );
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
  .upload__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    .aw-upload__img {
      .aw-upload__img--content {
        img {
          margin-right: 10px;
        }
      }
    }
    #lxp-file-input-issuer-logo {
      width: 0;
      visibility: hidden;
    }
    p {
      color: #a0a0a0;
      margin: 0;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.25px;
    }
    h6 {
      color: #434343;
      margin: 0;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.25px;
    }
  }
  .aw-image__container {
    display: flex;
    width: 190px;
    height: 89px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #c8c8c8;
    margin-right: 10px;
    .aw-image__container__remove {
      display: none;
    }
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        url(<path-to-image>) lightgray 50% / cover no-repeat;
      position: relative;
      .aw-image__container--img {
        opacity: 0.4;
      }
      .aw-image__container__remove {
        display: block;
        position: absolute;
        right: 8px;
        top: 8px;
        background: #fff;
        border-radius: 100px;
        padding: 8px;
        display: flex;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .aw-image__container--img {
      width: 60px;
    }
  }
  .aw-editor__form {
    width: 100%;
    &.aw-editor__validity {
      display: flex;
      gap: 16px;
      .aw-editor__validity--select {
        flex: 1;
      }
      .aw-editor__validity--date {
        width: 35%;
      }
    }
    .error {
      padding-top: 0px;
      margin-top: 0px;
      font-size: 12px;
      color: red;
      padding-left: 10px;
    }
    .lxp-editor {
      width: 100% !important;
      padding-right: 6px;
    }
    .aw-error__text {
      font-size: 11px;
      color: red;
      padding-left: 10px;
      font-weight: 500;
    }
    .aw-optional__field {
      display: flex;
      height: 56px;
      padding: 16px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #c8c8c8;
      cursor: pointer;
      p {
        color: #434343;
        margin: 0;
        /* WHOA Text Styles/body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.25px;
      }
    }
  }
  .aw-editor__btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
<style lang="scss">
.lxp-text_editor {
  &.lxp-text_editor__error {
    border-width: 1px !important;
  }
  .quill-editor {
    &.lxp-text_editor--label {
      padding: 0 16px 10px;
    }
  }
}
.aw-editor__btn {
  .lxp-loader {
    .spinner-border {
      color: #fff !important;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
